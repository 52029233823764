<template>
  <ValidationObserver slim ref="observer" v-slot="{ valid }">
    <EuiContainer class="es-setting" style="height: calc(100vh - 180px);">
      <form @submit.prevent="updateUser()" id="editUserForm">
        <EuiGrid gutters>
          <EuiGridRow>
            <EuiGridColumn>
              <div class="flex items-center">
                <EuiButton type="button" iconOnly size="large" variant="text" @click.prevent="goBack()">
                  <EuiIcon name="chevron_left" color="primary"/>
                </EuiButton>
                <EuiHeading :level="1" bold>{{ user.fullname }}</EuiHeading>
              </div>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow>
            <EuiGridColumn width="2/3">
              <EuiBlock :title="$i18n.t('settings.users.create.userInfo.title')">
                <div class="eui-u-flex eui-u-mb-6">
                  <div class="eui-u-flex-1">
                    <ValidationProvider :name="$t('settings.users.create.firstName.label')" rules="required" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    class="eui-u-mb-2"
                                    :label="$t('settings.users.create.firstName.label')"
                                    type="text"
                                    v-model="user.firstname"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"
                                    :disabled="!isEditable"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('settings.users.create.lastName.label')" rules="required" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    class="eui-u-mb-2"
                                    :label="$t('settings.users.create.lastName.label')"
                                    type="text"
                                    v-model="user.lastname"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"
                                    :disabled="!isEditable"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('settings.users.create.mobilePhone.label')" rules="required|phone" v-slot="{ errors, valid, invalid }" v-if="options && options.signerCreationFormDefineMobilePhone && options.signerCreationFormDefineMobilePhone.value === '1'">
                      <EuiTextField adaptSize
                                    class="eui-u-mb-2"
                                    :label="$t('settings.users.create.mobilePhone.label')"
                                    type="text"
                                    v-model="user.mobilePhone"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"
                                    :disabled="!isEditable"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('settings.users.create.email.label')" rules="required|email" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    class="eui-u-mb-2"
                                    :label="$t('settings.users.create.email.label')"
                                    type="text"
                                    v-model="user.email"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"
                                    :disabled="!isEditable"/>
                    </ValidationProvider>
                    <EuiCheckbox v-model="user.microsoftCredential">
                      {{ $t('settings.users.microsoft') }}
                    </EuiCheckBox>
                  </div>
                  <div class="eui-u-flex-1 eui-u-ml-5">
                    <ValidationProvider :name="$t('settings.users.create.group.label')" rules="required" v-slot="{ errors }">
                      <EuiTextField class="eui-u-mb-2" adaptSize :disabled="!isEditable">
                        <EuiLabel>{{ $t('settings.users.create.group.label') }}</EuiLabel>
                        <select class="eui-a-input -adaptSize -noIcon eui-u-px-2" v-model="user.userGroupId" required >
                          <option value="" selected disabled hidden>---</option>
                          <option v-for="option in groups" :value="option.id" :key="option.id">{{ option.name }}</option>
                        </select>
                        <p v-if="errors[0]" class="eui-u-my-4 eui-u-text-base eui-u-font-bold eui-u-text-red">
                          {{ errors[0] }}
                        </p>
                      </EuiTextField>
                    </ValidationProvider>
                  </div>
                </div>
                <template v-if="isEditable && !user.microsoftCredential">
                  <EuiHeading :level="4" bold>{{ $t('settings.users.create.credentials.title') }}</EuiHeading>
                  <div class="w-1/2">
                    <ValidationProvider :name="$t('settings.users.create.login.label')" rules="required" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    class="eui-u-mb-2"
                                    :label="$t('settings.users.create.login.label')"
                                    type="text"
                                    v-model="user.login"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('profile.label.newPassword')" :rules="shouldUpdatePassword ? 'required|profilePassword' : ''" v-slot="{ errors, valid, invalid }" autocomplete="off">
                      <EuiTextField adaptSize
                                    class="mb-2"
                                    :label="$t('profile.label.newPassword')"
                                    :type="passwordFieldType"
                                    v-model.trim="user.password"
                                    :valid="valid && validatePassword"
                                    :invalid="errors.length > 0 || !validatePassword"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"
                                    autocomplete="off"
                                    @keyup="passwordStrengthValidation"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('profile.label.confirmPassword')" :rules="shouldUpdatePassword ? 'required|profilePassword' : ''" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    class="mb-2"
                                    :label="$t('profile.label.confirmPassword')"
                                    :type="passwordFieldType"
                                    v-model.trim="confirmPassword"
                                    :valid="valid && validatePassword"
                                    :invalid="errors.length > 0 || !validatePassword"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"
                                    @keyup="passwordStrengthValidation"/>
                    </ValidationProvider>
                    <div class="flex">
                      <div class="flex flex-col flex-1 mt-2">
                        <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.is8long" :value="true" name="">{{ $t('settings.users.create.password.at.least.length') }}</EuiRadio>
                        <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.hasCaps" :value="true" name="">{{ $t('settings.users.create.password.at.least.caps') }}</EuiRadio>
                        <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.hasNumber" :value="true" name="">{{ $t('settings.users.create.password.at.least.number') }}</EuiRadio>
                        <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.hasSpecialChar" :value="true" name="">{{ $t('settings.users.create.password.at.least.special.char') }}</EuiRadio>
                      </div>
                      <div>
                        <EuiButton type="button" class="eui-u-p-2" color="primary" variant="text" @click.prevent="switchPasswordVisibility">
                          <template v-if="passwordFieldType === 'password'">
                            {{ $t('settings.users.create.password.show') }}
                          </template>
                          <template v-else>
                            {{ $t('settings.users.create.password.hide') }}
                          </template>
                        </EuiButton>
                      </div>
                    </div>
                    <p :style="{ visibility: isPasswordValid !== undefined && !isPasswordValid ? 'visible' : 'hidden' }" class="text-alert">{{ $t('settings.users.create.messages.create.validation.error.passwordMismatch') }}</p>
                  </div>
                </template>
              </EuiBlock>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow v-if="selectedGroup">
            <EuiGridColumn>
              <EuiBlock :title="$i18n.t('settings.users.create.userPermissions.title') + selectedGroup.name">
                <EuiTable>
                  <EuiTableHeader>
                    <EuiTableRow class="cursor-default">
                      <EuiTableHead> </EuiTableHead>
                      <EuiTableHead>{{ $t('settings.users.create.permissions.table.headers.createUpdate') }}</EuiTableHead>
                      <EuiTableHead>{{ $t('settings.users.create.permissions.table.headers.list') }}</EuiTableHead>
                      <EuiTableHead>{{ $t('settings.users.create.permissions.table.headers.other') }}</EuiTableHead>
                    </EuiTableRow>
                  </EuiTableHeader>
                  <EuiTableBody>
                    <EuiTableRow class="hover:bg-transparent cursor-default">
                      <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.create.permissions.table.headers.paraphers') }}</EuiTableHead>
                    </EuiTableRow>
                    <EuiTableRow class="hover:bg-transparent cursor-default">
                      <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.templates') }}</EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasWriteModelPermission === '1'" size="mini" name="check" color="success" outlined/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasReadModelPermission === '1'" size="mini" name="check" color="success" outlined/>
                      </EuiTableCell>
                      <EuiTableCell>
                      </EuiTableCell>
                    </EuiTableRow>
                    <EuiTableRow class="hover:bg-transparent cursor-default">
                      <EuiTableCell class="align-middle">{{ $t('settings.groups.create.permissions.table.headers.sharedTemplates') }}</EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasWholeSignbooksEditingPermission === '1'" size="mini" name="check" color="success" outlined/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasWholeSignbooksListingPermission === '1'" size="mini" name="check" color="success" outlined/>
                      </EuiTableCell>
                      <EuiTableCell>
                      </EuiTableCell>
                    </EuiTableRow>
                    <EuiTableRow class="hover:bg-transparent cursor-default">
                      <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.create.permissions.table.headers.users') }}</EuiTableHead>
                    </EuiTableRow>
                    <EuiTableRow class="hover:bg-transparent cursor-default">
                      <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.userRights') }}</EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasEditUserPermission === '1'" size="mini" name="check" color="success" outlined/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon size="mini" name="check" color="success" outlined/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasAccessRulesPermission === '1'" size="mini" name="check" color="success" outlined/>
                        <div v-if="selectedGroup.hasAccessRulesPermission === '1'" class="ml-2 inline-block">{{ $t('settings.groups.create.permissions.table.other.managePermissions') }}</div>
                      </EuiTableCell>
                    </EuiTableRow>
                    <EuiTableRow class="hover:bg-transparent cursor-default">
                      <EuiTableCell class="align-middle">
                        {{ $t('settings.groups.edit.permissions.table.headers.manageSocialSignatureAuthorizations') }}
                      </EuiTableCell>
                      <EuiTableCell>
                      </EuiTableCell>
                      <EuiTableCell>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiIcon v-if="selectedGroup.hasSocialSignerTriggerPermission === '1'" size="mini" name="check" color="success" outlined/>
                        <div v-if="selectedGroup.hasSocialSignerTriggerPermission === '1'" class="ml-2 inline-block">{{ $t('settings.groups.create.permissions.table.other.manageSocialSignatureAuthorizations') }}</div>
                      </EuiTableCell>
                    </EuiTableRow>
                  </EuiTableBody>
                </EuiTable>
              </EuiBlock>
            </EuiGridColumn>
          </EuiGridRow>
        </EuiGrid>
      </form>
    </EuiContainer>
    <EuiBottomBar v-if="isEditable">
      <EuiButton type="button" @click.prevent="goBack">{{ $t('button.cancel') }}</EuiButton>
      <EuiButton variant="raised" color="primary" type="submit" form="editUserForm" :disabled="(!valid || !validatePassword) || haveSameProperties">{{ $t('button.save') }}</EuiButton>
    </EuiBottomBar>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import DOMpurify from 'dompurify';

export default {
  /**
   * The name of the page.
   */
  name: 'EditUser',

  /**
   * The properties that can be used.
   */
  props: {
    /**
     * The user identifier used.
     */
    userId: {
      type: [String, Number],
      required: true,
    },
  },

  /**
   * The data the page can use.
   *
   * @returns {Object} The data.
   */
  data() {
    return {
      tmpLogin: '',
      selectedGroup: null,
      isPasswordValid: undefined,
      confirmPassword: '',
      passwordValidStrength: false,
      passwordFieldType: 'password',
      passwordValidRequirement: {
        is8long: false,
        hasCaps: false,
        hasNumber: false,
        hasSpecialChar: false,
      },
      tmpUser: {},
      user: {},
      groups: null,
    };
  },

  /**
   * The computed properties for the component.
   */
  computed: {
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
    }),
    userGroup() {
      return this.user.userGroupId;
    },
    isEditable() {
      return this.$store.state.ModuleEdocSign.auth.profile.features && this.$store.state.ModuleEdocSign.auth.profile.features.hasEditUserPermission === '1';
    },
    validatePassword() {
      return this.user.password === this.confirmPassword;
    },
    shouldUpdatePassword() {
      return (this.user.password !== '' || this.confirmPassword !== '');
    },
    shouldUpdateLogin() {
      return (this.user.login !== this.tmpLogin);
    },
    haveSameProperties() {
      return isEqual(this.user, this.tmpUser);
    },
  },

  /**
   * Available watchers for this page.
   */
  watch: {
    userId(value) {
      this.setLastBreadcrumb('');
      this.findById(value).then((response) => {
        this.user = response;
        this.setLastBreadcrumb(this.user.fullname);
      });
    },
    userGroup(value) {
      this.findGroupById(value).then((response) => {
        if (response) {
          this.selectedGroup = response;
        }
      });
    },
  },

  /**
   * The methods the page can use.
   */
  methods: {
    ...mapActions({
      setLastBreadcrumb: 'application/setLastBreadcrumb',
      findById: 'ModuleEdocSign/settingsUsers/findById',
      findGroupById: 'ModuleEdocSign/settingsGroups/findById',
      addUserToGroup: 'ModuleEdocSign/settingsGroups/addUserToGroup',
      searchGroup: 'ModuleEdocSign/settingsGroups/search',
      update: 'ModuleEdocSign/settingsUsers/update',
      pushAlert: 'application/pushAlert',
    }),
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },

    /**
     * Password strength validation
     */
    passwordStrengthValidation() {
      this.passwordValidRequirement.is8long = new RegExp('^(?=.{8,})').test(this.user.password);
      this.passwordValidRequirement.hasNumber = new RegExp('^(?=.*[0-9])').test(this.user.password);
      this.passwordValidRequirement.hasCaps = new RegExp('^(?=.*[A-Z])').test(this.user.password);
      this.passwordValidRequirement.hasSpecialChar = new RegExp('^(?=.*[!@#$%^&*])').test(this.user.password);
      let errorCount = 0;
      Object.keys(this.passwordValidRequirement).forEach((key) => {
        if (!this.passwordValidRequirement[key]) {
          errorCount += 1;
        }
      });
      if (errorCount === 0) {
        this.passwordValidStrength = true;
      }
    },
    updateUser() {
      const user = { ...this.user };
      // sanitize input for firstname and lastname
      user.firstname = DOMpurify.sanitize(user.firstname);
      user.lastname = DOMpurify.sanitize(user.lastname);
      user.fullname = DOMpurify.sanitize(user.fullname);
      if (user.password !== undefined && user.password.length === 0) {
        delete user.password;
      }
      if ((!user.password || (user.password && this.passwordValidStrength))) {
        delete user.group;
        this.update(user).then((response) => {
          if (response.status !== 'error') {
            this.addUserToGroup({ group: { id: user.userGroupId }, user: { userId: user.userId } }).then(() =>
              this.goBack());
          }
        });
      } else {
        this.pushAlert({
          message: this.$i18n.t('settings.groups.edit.error.passwordInvalid'),
          type: 'error',
        });
      }
    },
    goBack() {
      if (this.$store.state.route.from.name) {
        this.$router.push({ name: this.$store.state.route.from.name });
      } else {
        this.$router.push({ name: 'settings.users.index' });
      }
    },
    fetchUser() {
      this.setLastBreadcrumb('');
      const user = this.findById(this.userId).then((response) => {
        if (response && response.account) {
          this.tmpLogin = response.account.login;
          this.tmpUser = cloneDeep(response.account);
          return { ...response.account, password: '' };
        }
      });
      const groups = this.searchGroup({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 1000 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      Promise.all([user, groups]).then((values) => {
        const user = values[0];
        this.groups = values[1];
        this.selectedGroup = this.groups.find((element) => element.id === user.userGroupId);
        user.group = this.selectedGroup.name;
        this.setLastBreadcrumb(user.fullname);
        this.user = user;
        this.tmpUser = cloneDeep(user);
      });
    },
  },

  /**
   * This method will be fired once the application has been mounted.
   */
  mounted() {
    this.fetchUser();
  },
};
</script>
